import React from 'react'
import './BoxedText.less'

const BoxedText = ({children}) => (
    <div className="boxed-text">
        {children}
    </div>
)

export default BoxedText
