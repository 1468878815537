import React from "react";
import "./SmartLink.less";
import { Link } from "gatsby";

interface Props {
  to: string;
  children: React.ReactNode;
  punctuation?: string;
  inNewWindow?: boolean;
}

const SmartLink = (props: Props) => {
  const { children, to, punctuation, inNewWindow, ...rest } = props;

  let link = (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );

  if (inNewWindow) {
    link = (
      <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    );
  }

  return (
    <>
      {" "}
      <span className="smart-link">
        {link}
        {punctuation}
      </span>{" "}
    </>
  );
};

SmartLink.defaultProps = {
  punctuation: "",
  inNewWindow: false,
};

export default SmartLink;
