import React from "react";
import "./HomeSpecial.less";
import PageWidth from "../../organisms/PageWidth/PageWidth";
import SmartLink from "../SmartLink/SmartLink";

const HomeSpecial = () => (
  <PageWidth isContent={true}>
    <div className="home-special">
      <div className="home-special__text">
        {/* <div className="home-special__tag">Aus aktuellem Anlass:</div> */}
        <h1>Apps, WebApps, Websites</h1>
        <div className="text-max-length">
          <p style={{ marginTop: -20, fontSize: "1.4rem", color: "#999" }}>
            Around 25 years of experience in software.
            <SmartLink to="/freelance">See freelance profile</SmartLink>
          </p>
        </div>
      </div>
    </div>
  </PageWidth>
);

export default HomeSpecial;
