import React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/organisms/Layout/Layout";
import SEO from "../components/atoms/SEO/SEO";
import HomeSpecial from "../components/atoms/HomeSpecial/HomeSpecial";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import BoxedText from "../components/atoms/BoxedText/BoxedText";
import SmartLink from "../components/atoms/SmartLink/SmartLink";

const HomePage = (props: PageProps) => (
  <Layout>
    <SEO title="Home" keywords={[`hamburg`, `react`, `react-native`]} />
    <HomeSpecial />
    <PageWidth>
      <BoxedText>
        <p>Hi, I'm Ludwig.</p>
        <p>A software architect & engineer from Hamburg.</p>
        <p>
          I am available for <SmartLink to="/freelance">freelance</SmartLink>
          work on <em>React</em>, <em>React Native</em>, <em>Flutter</em> and <em>Node.js</em> projects.
        </p>
        <p>
          Get in touch with me on
          <SmartLink
            to="https://www.xing.com/profile/KarlLudwig_Weise"
            inNewWindow={true}
            punctuation="."
          >
            XING
          </SmartLink>
        </p>
      </BoxedText>
    </PageWidth>
  </Layout>
);

export default HomePage;
